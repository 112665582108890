import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;

    data: any;
    totalScore: number;

    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;
    activeWindow = 1;

    currentValue;

    photoArray;
    tabs = [];
    tabCount: number;

    styleLeftPx = 11;

    loading = true;

    constructor(
        private afs: AngularFirestore,
        private auth: ActivityService,
        public storage: AngularFireStorage) { }

    async ngOnInit() {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const videos = [];

        var childData = await this.auth.getSelfData({ collectionID: "userData", token: localStorage.getItem("accessToken") }).toPromise();
        this.userInfo = childData.result;


        this.userData = this.afs.collection('userData').doc(user.uid);
        const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        this.afs.collection('activity-lists').snapshotChanges().pipe(map(changes => {
            return combineLatest(changes.map(a => {
                return this.afs.collection('activity-lists').doc(a.payload.doc.id).collection('activities', ref => ref.where('type', '==', 'video')).get().pipe(map(games => {
                    const data = [];
                    games.forEach(game => {
                        const gameData = game.data();
                        let url;
                        let lock = false;
                        if (adminUserData || (this.userInfo.activities && this.userInfo.activities[a.payload.doc.id] && this.userInfo.activities[a.payload.doc.id][gameData.orderNumber])) {
                            url = ['/game/activity-video', a.payload.doc.id, gameData.orderNumber];
                        } else {
                            lock = true;
                        }
                        const doc: any = a.payload.doc.data();
                        data.push({
                            countryTitle: doc.countryTitle,
                            orderNumber: doc.order,
                            type: gameData.type,
                            title: gameData.title,
                            poster: '/assets/games/video/poster-' + a.payload.doc.id + '-' + gameData.orderNumber + '.png',
                            url,
                            lock
                        });
                    });
                    return data;
                }));
            }));
        })).subscribe(data => {
            data.subscribe(data => {
                let games = [];
                data.forEach(activities => {
                    games = games.concat(activities.filter(item => item.title == 'Story Zone'));
                });
                this.tabs = [];
                this.createArray(games.concat(videos));
            });
        });
    }

    private createArray(videos) {
        this.tabCount = Math.ceil(videos.length / 6);

        for (let i = 0; i < this.tabCount; i++) {
            const inarray = [];
            for (let j = (6 * i); j < (6 * i) + 6; j++) {
                if (videos[j]) {
                    inarray.push(videos[j]);
                }
            }
            this.tabs.push(inarray);
        }

        this.styleLeftPx = this.tabs.length * 11;
        this.loading = false;
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // if(window.angularComponent)
        // 	window.angularComponent = null;
    }

    ngAfterViewInit(): void {
        const buttons = document.querySelectorAll('button');
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                const audio = document.getElementById('buttonClick') as HTMLAudioElement;
                audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                const audio = document.getElementById('buttonHover') as HTMLAudioElement;
                audio.play();
            });
        }
    }

    previousState() {
        window.history.back();
    }

    previous() {
        if (this.activeWindow > 1) {
            --this.activeWindow;
        }
    }
    next() {
        if (this.activeWindow < this.tabCount) {
            ++this.activeWindow;
        }
    }
}
