import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

declare function questionAndAnswers(data: any): any;

declare function destroyQuestionAndAnswers(): any;

@Component({
    selector: 'app-q-a',
    templateUrl: './q-a.component.html'
})
export class QuestionAndAnswerComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;

    userActivities: any = {};
    id: string;
    activityId: string;

    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;
    data: any;

    loading: boolean = true;
    private loadAPI: Promise<any>;
    private loadingSubscribe: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private _ngZone: NgZone,
        private frame: FrameShowService,
        public activityService: ActivityService,
        private pm: PointsMechanismService) {
        window['angularComponentRef'] = { component: this, zone: this._ngZone, gameFinish: (value) => this.gameFinish(value), backToMenu: () => this.backToMenu(), nextActivity: () => this.nextActivity(), getTotalScore: () => this.getTotalScore(), loadingFinished: () => this.loadingFinished() };
    }

    ngOnInit() {
        this.frame.useV3();
        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id'];
                this.activityId = data['activityId'];
                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });
                this.pm.start(this.id, this.activityId);
                this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {
                    this.data = aData.activityData;
                    this.loading = false;
                    setTimeout(() => {
                        questionAndAnswers(this.data);
                    }, 3000);
                });
            }
        });
    }
    ngAfterViewInit(): void {
        var buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                audio.play();
            });
        }
    }

    gameFinish(data) {
        this.pm.collectPoint(data.totalScore, this.data.progressType);
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
        if (window['angularComponentRef'])
            window['angularComponentRef'] = null;
        destroyQuestionAndAnswers();
    }

    previousState() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo ? this.userInfo.totalScore : 0;
    }

    loadingFinished() {
        this.loading = false;
    }
}
