import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PointsMechanismService } from '../../../shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

//import shData from '../../../../../json_data/2-2-1.json';

declare function factoryGame(data: any);

declare function destroyFactoryGame();

@Component({
    selector: 'app-factory-game',
    templateUrl: './factory.game.component.html',
    styleUrls: ['./factory.game.component.scss']
})
export class FactoryGameComponent implements OnInit, OnDestroy {
    protected subscription: Subscription;
    userInfo: any;
    loading: boolean;
    data: any;
    id: any;

    constructor(private activatedRoute: ActivatedRoute,
        private _ngZone: NgZone,
        private frame: FrameShowService,
        private pm: PointsMechanismService,
        public activityService: ActivityService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this._ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {
        this.frame.useV3();
        this.frame.callAfterDone(() => {
            this.subscription = this.activatedRoute.params.subscribe((data) => {
                this.id = data['id'];

                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });

                this.pm.start(this.id, data['activityId']);

                this.activityService.getActivityData({ zoneId: this.id, activityId: data.activityId }).subscribe(aData => {
                    setTimeout(() => {
                        factoryGame(aData.activityData['data']);
                    }, 500);
                    this.data = aData.activityData;
                });
            });
        });
    }

    gameFinish(value: any) {
        this.pm.collectPoint(value.score, this.data.progressType);
    }

    backToMenu() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }

    ngOnDestroy(): void {
        destroyFactoryGame();
        window['angularComponentRef'] = null;
        this.subscription.unsubscribe();
    }
}
