import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

declare function matchTheWord(data: any): any;

declare function destroyMatchTheWord(): any;

@Component({
    selector: 'app-match-the-word',
    templateUrl: './match-the-word.component.html'
})
export class MatchTheWordActivityComponent implements OnInit, OnDestroy, AfterViewInit {


    protected subscription: Subscription;
    userData: any;

    userActivities: any = {};
    id: string;
    activityId: string;


    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;
    data: any;

    loading = true;

    private loadAPI: Promise<any>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private frame: FrameShowService,
        private _ngZone: NgZone,
        public activityService: ActivityService,
        private pm: PointsMechanismService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this._ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {
        this.frame.useV3();
        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id'];
                this.activityId = data['activityId'];
                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });
                this.pm.start(this.id, this.activityId);
                this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {
                    setTimeout(() => {
                        matchTheWord(aData.activityData);
                    }, 2000);
                    this.data = aData.activityData;
                });
            }
        });
    }

    ngAfterViewInit(): void {
        const buttons = document.querySelectorAll('button');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', (event) => {
                const audio = document.getElementById('buttonClick') as HTMLAudioElement;
                audio.play();
            });
            buttons[i].addEventListener('mouseover', (event) => {
                const audio = document.getElementById('buttonHover') as HTMLAudioElement;
                audio.play();
            });
        }
    }

    gameFinish(data) {
        this.pm.collectPoint(data.totalScore, this.data.progressType);
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (window['angularComponentRef']) {
            window['angularComponentRef'] = null;
        }
        destroyMatchTheWord();
    }

    previousState() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }
}
