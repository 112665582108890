import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-africa-2',
    templateUrl: './africa-2.component.html',
    styleUrls: ['./africa-2.component.scss']
})
export class Africa2Component implements OnInit, OnDestroy {
    zones = [
        {
            id: '3-2-1',
            number: 33,
            class: 'egypt',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-2-2',
            number: 34,
            class: 'sudan',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-2-3',
            number: 35,
            class: 'somalia',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-2-4',
            number: 36,
            class: 'kenya',
            locked: true,
            active: false,
            hover: false
        }
    ];

    public activeZone = 1;
    user: any;
    openZones: number;
    activeContinent: number;
    isAdmin = false;
    tick2Quiz = true;
    adminUserData;
    quizLevel: any;
    plane: any = {
        position: 1
    };
    loading = true;

    constructor(private router: Router, private auth: AuthService) { }

    ngOnInit() {
        this.tick2Quiz = true;
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }
    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        this.zones = [
            {
                id: '3-2-1',
                number: 33,
                class: 'egypt',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-2-2',
                number: 34,
                class: 'sudan',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-2-3',
                number: 35,
                class: 'somalia',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-2-4',
                number: 36,
                class: 'kenya',
                locked: true,
                active: false,
                hover: false
            }
        ];

        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        if (this.adminUserData) {
            this.isAdmin = true;
            userData.activeLevel = '9-9-9';
        }

        if (userData.activities["3-3-1"]) {
            this.tick2Quiz = false;
        }

        this.user = userData;
        // this.openZones = userData.zone1Lvl;
        this.plane.position = this.openZones;
        this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split('-')[1], 10);
        this.activeContinent = this.isAdmin ? 3 : parseInt(userData.activeLevel.split('-')[0], 10);
        if (this.activeZone > 3) {
            this.openZones = 5;
        } else {
            this.openZones = parseInt(userData.activeLevel.split('-')[2], 10);
        }
        if (userData.quizLevel) {
            this.quizLevel = userData.quizLevel;
        }
        if (this.adminUserData) {
            this.quizLevel = 100;
        }
        this.remapZones();
    }

    goQuiz() {
        if (this.quizLevel >= 9) {
            this.router.navigate(['/game/quiz-activity/3-2']);
        }
    }

    remapZones() {
        this.zones.map(zone => {
            if (this.activeContinent < 3) return;

            if (zone.number > 36) return;

            if (!this.user.activities[zone.id]) {
                zone.locked = this.isAdmin ? false : true;
            } else {
                zone.locked = this.isAdmin ? false : false;
            }
            if (this.user.activeLevel === zone.id && this.user.activities[zone.id]) {
                zone.active = true;
            }
        });
    }

    mouseEnter(zone) {
        const audio = document.getElementById('continentHover') as HTMLAudioElement;
        audio.pause();
        audio.currentTime = 0;
        audio.play();
        zone.hover = true;
    }

    mouseLeave(zone) {
        zone.hover = false;
    }

    selectZone(zone) {
        if (!zone.locked) {
            const audio = document.getElementById('continentClick') as HTMLAudioElement;
            audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/' + zone.id]);
            }, 1000);
        }
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        audio.play();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            audio.play();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 2000);
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;
        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
