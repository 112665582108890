import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-africa-3',
    templateUrl: './africa-3.component.html',
    styleUrls: ['./africa-3.component.scss']
})
export class Africa3Component implements OnInit, OnDestroy {
    zones = [
        {
            id: '3-3-1',
            number: 37,
            class: 'madagascar',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-3-2',
            number: 38,
            class: 'zimbabwe',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-3-3',
            number: 39,
            class: 'namibia',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '3-3-4',
            number: 40,
            class: 'southAfrica',
            locked: true,
            active: false,
            hover: false
        }
    ];

    public activeZone = 1;
    openZones: number;
    user: any;
    adminUserData;
    isAdmin = false;
    activeContinent: number;
    showNotification = false;
    quizLevel: any;
    tick2Quiz = true;
    plane: any = {
        position: 1
    };
    loading = true;

    constructor(private router: Router, private auth: AuthService) { }

    ngOnInit() {
        this.tick2Quiz = true;
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }
    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        this.zones = [
            {
                id: '3-3-1',
                number: 37,
                class: 'madagascar',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-3-2',
                number: 38,
                class: 'zimbabwe',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-3-3',
                number: 39,
                class: 'namibia',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '3-3-4',
                number: 40,
                class: 'southAfrica',
                locked: true,
                active: false,
                hover: false
            }
        ];

        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        if (this.adminUserData) {
            this.isAdmin = true;
            userData.activeLevel = '9-9-9';
        }

        if (userData.activities["4-1-1"]) {
            this.tick2Quiz = false;
        }

        this.user = userData;
        // this.openZones = userData.zone1Lvl;
        this.plane.position = this.openZones;
        this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split('-')[1], 10);
        this.activeContinent = this.isAdmin ? 99 : parseInt(userData.activeLevel.split('-')[0], 10);
        if (this.activeZone > 3 || this.activeContinent > 3) {
            this.openZones = 5;
        } else {
            this.openZones = parseInt(userData.activeLevel.split('-')[2], 10);
            console.log(this.openZones);
        }
        if (userData.quizLevel) {
            this.quizLevel = userData.quizLevel;
        }
        if (this.adminUserData) {
            this.quizLevel = 100;
        }
        this.remapZones();
    }

    goQuiz() {
        if (this.quizLevel >= 10) {
            this.router.navigate(['/game/quiz-activity/3-3']);
        }
    }

    remapZones() {
        this.zones.map((zone, i) => {
            if (i + 1 <= this.openZones) {
                this.zones[i].locked = false;
            }
            if (i + 1 === this.openZones) {
                this.zones[i].active = true;
            }
        });
    }

    mouseEnter(zone) {
        const audio = document.getElementById('continentHover') as HTMLAudioElement;
        audio.pause();
        audio.currentTime = 0;
        audio.play();
        zone.hover = true;
    }

    mouseLeave(zone) {
        zone.hover = false;
    }

    selectZone(zone) {
        if (zone.number > 40) {
            this.showNotification = true;
            return;
        }
        if (!zone.locked) {
            const audio = document.getElementById('continentClick') as HTMLAudioElement;
            audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/' + zone.id]);
            }, 1000);
        }
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        audio.play();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            audio.play();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 2000);
    }

    closeNotification() {
        this.showNotification = false;
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;
        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
