import { Component, Injectable, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

// @Component({
//   encapsulation: ViewEncapsulation.None,
// })

export class AppService {

  loading = false;
  settings = {};

  constructor(
    private snackBar: MatSnackBar
  ) { }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  public snack(message) {
    this.snackWithoutOk(message);
    // this.snackBar.open(message, 'ok', { duration: 3000 });
  }

  public snackWithoutOk(message) {
    this.snackBar.open(message, '', { duration: 3000, panelClass: 'simple-snack-bar' });
  }
}
